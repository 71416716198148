import Logo from "./components/Logo.vue";
import { Options, Vue } from "vue-class-component";
import Search from "@/components/Search.vue";
import { ref } from "vue";
import { radioFormatOption } from "@/models/radioData";
@Options({
	components: { Logo, Search },
	data() {
		return {
			modalSearch: false,
			radioFormat: ref(""),
			options: radioFormatOption,
			displayMenu: false,
		};
	},
	setup() {
		const handleSelect = (key: any, keyPath: any) => {};

		return {
			handleSelect,
		};
	},
	watch: {
		activateDialogVisible(value: boolean) {
			this.modalSearch = value;
		},
		radioFormat(value: string) {
			if (this.$route !== "/") {
				this.$store.commit("loadTypeComic", value);
			}
		},
		$route(to) {
			if (to.name == "home" || to.name == "search") {
				this.displayMenu = true;
			} else {
				this.displayMenu = false;
			}
		},
	},
	methods: {
		updateDisplaySearchComponent() {
			this.$store.commit("loadActivateDialogVisible", true);
		},
	},
	computed: {
		searchInput() {
			return this.$store.state.searchInput;
		},
		activateDialogVisible: function () {
			return this.$store.getters.getActivateDialogVisible;
		},
	},
})
export default class App extends Vue {}
