import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9b67e44"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"3rem"}
}
const _hoisted_2 = {
  key: 1,
  style: {"margin-top":"3rem"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  class: "comics",
  id: "comics",
  ref: "comics"
}
const _hoisted_8 = { class: "comic" }
const _hoisted_9 = { class: "ribbon" }
const _hoisted_10 = { class: "behind" }
const _hoisted_11 = { class: "content comic__title" }
const _hoisted_12 = { class: "comic_description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "container",
      onSearchNow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callApi()))
    }, [
      (!this.loading)
        ? _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_1, " Comics list ", 512)), [
            [_vShow, _ctx.$route.name == 'home']
          ])
        : _createCommentVNode("", true),
      (!this.loading)
        ? _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_2, " Results ", 512)), [
            [_vShow, _ctx.$route.name == 'search']
          ])
        : _createCommentVNode("", true),
      (!this.loading)
        ? _withDirectives((_openBlock(), _createBlock(_component_el_row, {
            key: 2,
            class: "results"
          }, {
            default: _withCtx(() => [
              (_ctx.resultsNumber)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_3, " Results found : " + _toDisplayString(_ctx.resultsNumber) + " comics ", 1))
                : _createCommentVNode("", true),
              (!_ctx.resultsNumber)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_4, "No results found"))
                : _createCommentVNode("", true),
              (_ctx.searchInput)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_5, "for   \"" + _toDisplayString(_ctx.searchInput) + "\"", 1))
                : _createCommentVNode("", true),
              (_ctx.typeComic)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_6, "in " + _toDisplayString(_ctx.typeComic), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512)), [
            [_vShow, _ctx.$route.name == 'search']
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_row, {
          gutter: 4,
          style: {"justify-content":"center"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comics, (comicInd, idx) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                to: { name: 'comic', params: { id: comicInd.id } },
                key: idx
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 2,
                    xl: 2
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", {
                          class: "carte",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit("changeDialogValue")))
                        }, [
                          _createElementVNode("div", {
                            class: "ahead",
                            style: _normalizeStyle({
										backgroundImage:
											'url(\'' + comicInd.cover + '\')',
									})
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("span", {
                                class: "ribbon__content",
                                style: _normalizeStyle({
												backgroundColor:
													_ctx.colorChipsFunction(
														comicInd.type
													),
											})
                              }, _toDisplayString(comicInd.type), 5)
                            ])
                          ], 4),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("h5", null, _toDisplayString(comicInd.titleC), 1),
                              _createElementVNode("div", _hoisted_12, _toDisplayString(comicInd.description), 1)
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        })
      ], 512),
      (this.loading)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 3,
            class: "loadingDiv"
          }))
        : _createCommentVNode("", true)
    ], 32),
    _createVNode(_component_el_row, { class: "pagination" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 20,
          style: {"justify-content":"center !important"}
        }, {
          default: _withCtx(() => [
            (!this.loading)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_pagination, {
                  key: 0,
                  background: "",
                  "current-page": this.paginationPage,
                  "page-size": 20,
                  "pager-count": 5,
                  onCurrentChange: _ctx.setPage,
                  layout: "prev, pager, next",
                  total: _ctx.pagination.total
                }, null, 8, ["current-page", "onCurrentChange", "total"])), [
                  [_vShow, this.resultsNumber]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}