import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Comic from "../components/Comic.vue";
import Error from "@/components/404.vue";

import About from "@/views/About.vue";
import Search from "@/components/MainContent.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: Home,
	},

	{
		path: "/about",
		name: "about",
		component: About,
	},
	{
		path: "/search",
		name: "search",
		component: Search,
	},
	{
		name: "comic",
		path: "/comic/:id",
		component: Comic,
		props: true,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		component: Error,
		props: true,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
