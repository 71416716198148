
	import { defineComponent } from "vue";

	export default defineComponent({
		name: "Search",
		props: ["radioFormat"],
		data() {
			return {
				searchInput: "",
				modalSearch: false,
			};
		},
		methods: {
			searchInputSave() {
				if (this.$route.name != "search")
					this.$router.push({ path: "/search" });
				this.$store.commit("loadSearchInput", this.searchInput);
				this.$store.commit("loadTypeComic", this.radioFormat);
				this.$store.commit("loadActivateDialogVisible", false);
			},
		},
		computed: {
			activateDialogVisible: function () {
				return this.$store.getters.getActivateDialogVisible;
			},
		},
	});
