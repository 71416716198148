
	import { Options, Vue } from "vue-class-component";
	@Options({
		components: {},
		methods: {
			pushHome() {
				this.$router.push({ name: "home" });
			},
		},
	})
	export default class About extends Vue {}
