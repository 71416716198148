// import { RadioTab } from '../types/radioFormatOption';
import { reactive } from "vue";

const colorChips = new Map<string, string>([
	["Magazine", "rgb(23,158,184)"],
	["Comic", "rgb(247,0,200)"],
	["Trade Paperback", "rgb(114,178,234)"],
	["Hardcover", "rgb(0,201,202)"],
	["Digest", "rgb(124,184,68)"],
	["Graphic Novel", "rgb(111,75,15)"],
	["Digital Comic", "rgb(101,247,0)"],
	["Infinite Comic", "rgb(43,133,182)"],
]);
export { colorChips };
