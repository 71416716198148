<template>
	<div class="comic" v-if="comic.title">
		<div
			class="comic__background"
			:style="{ backgroundImage: 'url(\'' + comic.cover + '\')' }"
		></div>
		<div
			class="comic__image"
			:style="{ backgroundImage: 'url(\'' + comic.cover + '\')' }"
		/>
		<router-link :to="{ name: 'home' }">
			<el-button
				@click="deleteCurrentComic()"
				class="comic__back"
				type="danger"
				>← Back</el-button
			>
		</router-link>
		<h1 class="comic__title">{{ comic.titleC }}</h1>
		<div class="comic__note"></div>

		<div>
			<review :comic="comic" />
		</div>
	</div>
</template>

<script>
	import Review from "./Review.vue";
	import Error from "./404.vue";

	export default {
		name: "comic",
		props: ["id"],
		components: {
			Review,
			Error,
		},
		created() {
			// mutation
			this.$store.commit("setCurrentComicVersionMutation", this.id);
		},
		computed: {
			comic: function () {
				return this.$store.getters.getCurrentComic;
			},
		},
		data: function () {
			return {};
		},
		methods: {
			deleteCurrentComic() {
				this.$store.commit("setCurrentComicVersionMutation", "");
			},
		},
	};
</script>

<style scoped>
	@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");

	* {
		font-family: "Poppins", sans-serif;
		box-sizing: border-box;
	}
	.comic {
		min-height: 100vh;
		max-width: 400px;
		margin: 0 auto;
		padding: 32px;
	}

	.comic__back {
		color: #ececec;
		font-weight: 400;
		text-decoration: none;
		margin: 22px 0px;
		display: inline-block;
		z-index: 23;
	}

	.comic__image {
		position: relative;
		max-width: 100%;
		height: 0;
		padding-bottom: 154%;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 auto;
		border-radius: 16px;
		z-index: 999;
	}

	.comic__background {
		padding: 32px;
		background-size: cover;
		background-position: center center;
		filter: blur(8px);
		height: 400px;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		z-index: 999;
	}

	.comic__title {
		font-size: 24px;
		padding: 8px;
		font-weight: normal;
		color: white;
	}

	/*     
  .comic__star {
    text-shadow: 0 0 8px #ffdc188c, 0 0 16px #ffffff54;
  }

  .comic__star:hover {
    cursor:pointer;
  }

  .comic__star--dark {
    opacity:0.5;
    text-shadow: none;
  } */
</style>
