export class Comic  {
constructor(){

}
	public id: number = 0;
	public title: string = "";
	public format: string = "";
	public collectionURI: string = "";
	public creators: string[]= [""] ;
	public publishedDate: string|any= "";
	public description: string= "";
	public language: string= "";
	public type: string= "";
	public cover: string= "";

	get titleC (){
		const titleArr = this.title.split("(")
		return titleArr[0]
	}
};
