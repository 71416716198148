<template>
	<el-row>
		<el-col>
			<svg>
				<filter id="filter">
					<feTurbulence
						type="turbulence"
						baseFrequency="0.002 0.008"
						numOctaves="2"
						seed="2"
						stitchTiles="stitch"
						result="turbulence"
					/>
					<feColorMatrix
						type="saturate"
						values="30"
						in="turbulence"
						result="colormatrix"
					/>
					<feColorMatrix
						type="matrix"
						values="1 0 0 0 0
  0 1 0 0 0
  0 0 1 0 0
  0 0 0 150 -15"
						in="colormatrix"
						result="colormatrix1"
					/>
					<feComposite
						in="SourceGraphic"
						in2="colormatrix1"
						operator="in"
						result="composite"
					/>
					<feDisplacementMap
						in="SourceGraphic"
						in2="colormatrix1"
						scale="1"
						xChannelSelector="R"
						yChannelSelector="A"
						result="displacementMap"
					/>
				</filter>
			</svg>
		</el-col>
	</el-row>

	<a class="text" href="https://marvel.foxbane.fr/#/">COMICSRIP</a>
</template>
<style lang="scss" scoped>
	body {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		background: transparent;
		text-align: center;
		width: 60px;
	}

	.text {
		cursor: pointer !important;
		margin-right: 2em !important;
		font: 2em Arial Black, sans-serif !important;
		white-space: nowrap;
		filter: url(#filter);
		outline: none;
		color: rgb(239, 18, 18) !important;
		text-decoration: none;
		&::selection {
			background: rgba(255, 255, 255, 0.2);
		}
	}

	svg {
		position: absolute;
		width: 0;
		height: 0;
	}
</style>
