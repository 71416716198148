import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "element-theme-dark";
import VueApexCharts from "vue3-apexcharts";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createI18n } from "vue-i18n";
// Optionally install the BootstrapVue icon components plugin

const i18nConfig = {
	locale: "en",
	fallbackLocale: "en",
	messages: {
		en: require("@/i18n/en.json"),
		ch: require("@/i18n/cn.json"),
		es: require("@/i18n/es.json"),
		ir: require("@/i18n/ir.json"),
		br: require("@/i18n/br.json"),
	},
};

library.add(faUserSecret);
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router)

	.use(store, key)
	.use(ElementPlus)
	.use(VueAxios, axios)
	.use(VueApexCharts)
	.use(createI18n(i18nConfig))
	.mount("#app");

// Optionally install the BootstrapVue icon components plugin
