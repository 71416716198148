<template>
	<div class="container">
		<div class="stage">
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
			<div class="layer"></div>
		</div>
		<el-button
			style="position: absolute; bottom: 6rem; left: 2rem"
			type="primary"
			@click="$router.push({ name: 'home' })"
			>Go to home page</el-button
		>
	</div>
</template>
<style lang="scss" scoped>
	@import url("//fonts.googleapis.com/css?family=Pacifico&text=Pure");
	@import url("//fonts.googleapis.com/css?family=Roboto:700&text=css");
	@import url("//fonts.googleapis.com/css?family=Kaushan+Script&text=!");

	.container {
		min-height: 450px;
		height: 100vh;
		margin: 0;
		background: radial-gradient(
			circle,
			darken(rgba(255, 30, 60, 0.767), 10%),
			#961f2f,
			#1b2949,
			#000
		);
	}
	.el-button {
		font-family: "Pacifico" !important;
		font-size: 1rem;
	}
	.stage {
		height: 300px;
		width: 50rem;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		perspective: 9999px;
		transform-style: preserve-3d;
	}

	.layer {
		width: 100%;
		height: 100%;
		position: absolute;
		transform-style: preserve-3d;
		animation: ಠ_ಠ 5s infinite alternate ease-in-out -7.5s;
		animation-fill-mode: forwards;
		transform: rotateY(40deg) rotateX(33deg) translateZ(0);
	}

	.layer:after {
		font: 150px/0.65 "Pacifico", "Kaushan Script", Futura, "Roboto",
			"Trebuchet MS", Helvetica, sans-serif;
		content: "404  :( \A   Page not\A Found";
		white-space: pre;
		text-align: center;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 50px;
		color: darken(#fff, 4%);
		letter-spacing: -2px;
		text-shadow: 4px 0 10px hsla(0, 0%, 0%, 0.13);
	}

	$i: 1;
	$NUM_LAYERS: 20;
	@for $i from 1 through $NUM_LAYERS {
		.layer:nth-child(#{$i}):after {
			transform: translateZ(($i - 1) * -1.5px);
		}
	}

	.layer:nth-child(n + #{round($NUM_LAYERS/2)}):after {
		-webkit-text-stroke: 3px hsla(0, 0%, 0%, 0.25);
	}

	.layer:nth-child(n + #{round($NUM_LAYERS/2 + 1)}):after {
		-webkit-text-stroke: 15px rgba(255, 30, 60, 0.767);
		text-shadow: 6px 0 6px darken(rgba(255, 30, 60, 0.767), 35%),
			5px 5px 5px darken(rgba(255, 30, 60, 0.767), 40%),
			0 6px 6px darken(rgba(255, 30, 60, 0.767), 35%);
	}

	.layer:nth-child(n + #{round($NUM_LAYERS/2 + 2)}):after {
		-webkit-text-stroke: 15px darken(rgba(255, 30, 60, 0.767), 10%);
	}

	.layer:last-child:after {
		-webkit-text-stroke: 17px hsla(0, 0%, 0%, 0.1);
	}

	.layer:first-child:after {
		color: #fff;
		text-shadow: none;
	}

	@keyframes ಠ_ಠ {
		100% {
			transform: rotateY(-40deg) rotateX(-43deg);
		}
	}
</style>
