import moment from "moment";
import { Comic } from "../types/Comic";
export let createComic = {
	comicsArray(results: any) {
		const comics = [];
		for (var i = 0; i < results.length; i++) {
				comics.push(this.comicObject(results[i]));
		}
		return comics;
	},
	comicObject(result: any) {
		const textObject = result.textObjects.length
			? result.textObjects[0]
			: { text: "", language: "", type: "" };
		const publicashedDatendx = result.dates.find(
			(d: any) => d.type == "onsaleDate"
		);
		const imgCover =
			result.thumbnail.path ==
			"http://i.annihil.us/u/prod/marvel/i/mg/b/40/image_not_available"
				? "https://wallpapercave.com/wp/wp4151494.jpg"
				: result.thumbnail.path + "/portrait_uncanny.jpg";
		const comic = new Comic();
		(comic.id = result.id),
			(comic.title = result.title),
			(comic.collectionURI = result.characters.collectionURI),
			(comic.creators = result.creators.items),
			(comic.publishedDate = publicashedDatendx
				? this.format_date(publicashedDatendx.date)
				: ""),
			(comic.description = textObject.text),
			(comic.language = textObject.language),
			(comic.type = result.format),
			(comic.cover = imgCover);

		return comic;
	},

	format_date(value: string) {
		if (value) {
			return moment(String(value)).format("DD MMM YYYY");
		}
	},
};
