import axios from "axios";

export let LoadData = {
	async LoadDataComics(requestParameters: object) {
		let data, errored, loadingG;
		await axios
			// ts=1&apikey=ec0856110fb4550329bebac9c15c9889&hash=a1740b4eb4fa327885b3a825735c6285

			// apikey=95811e33a3bd84de3aa746b2f829948c&hash=5d5792add189888c08c63becbdb86cb9&ts=1

			// apikey=8c19818d81ed447fa7cdd560214a19d9&hash=28a28b3f11e13b030ec8dcd4babbac5c&ts=1

			.get(
				`https://gateway.marvel.com:443/v1/public/comics?noVariants=true&orderBy=focDate&ts=1&apikey=ec0856110fb4550329bebac9c15c9889&hash=a1740b4eb4fa327885b3a825735c6285`,
				{
					params: requestParameters,
				}
			)

			.then((response) => {
				data = response.data;
			})
			.catch((error) => {
				console.log(error);
				errored = true;
			})
			.finally(() => (loadingG = false));

		return { data, errored, loadingG };
	},
	async LoadComic(id: number) {
		let data, errored, loadingC;
		await axios

			.get(
				`https://gateway.marvel.com:443/v1/public/comics/${id}?&ts=1&apikey=ec0856110fb4550329bebac9c15c9889&hash=a1740b4eb4fa327885b3a825735c6285`
			)

			.then((response) => {
				data = response.data;
			})
			.catch((error) => {
				console.log(error);
				errored = true;
			})
			.finally(() => (loadingC = false));

		return { data, errored, loadingC };
	},
	async LoadCreator(string: string) {
		let data, errored, loadingC;
		await axios

			.get(
				`${string}?&ts=1&apikey=ec0856110fb4550329bebac9c15c9889&hash=a1740b4eb4fa327885b3a825735c6285`
			)

			.then((response) => {
				data = response.data;
			})
			.catch((error) => {
				console.log(error);
				errored = true;
			})
			.finally(() => (loadingC = false));

		return { data, errored, loadingC };
	},
};

export let LoadDataLastWeek = {
	async LoadDataComics() {
		let data, errored, loadingC;
		await axios

			.get(
				`https://gateway.marvel.com:443/v1/public/comics?&dateDescriptor=lastWeek&ts=1&apikey=ec0856110fb4550329bebac9c15c9889&hash=a1740b4eb4fa327885b3a825735c6285`
			)

			.then((response) => {
				data = response.data;
			})
			.catch((error) => {
				console.log(error);
				errored = true;
			})
			.finally(() => (loadingC = false));

		return { data, errored, loadingC };
	},
};
