// import { RadioTab } from '../types/radioFormatOption';

type RadioTab = {
	name: string;
	path: string;
};
let radioFormatOption: RadioTab[] = [
	{ name: "all", path: "" },
	{ name: "magazine", path: "magazine" },
	{ name: "comic", path: "comic" },
	{ name: "trade paperback", path: "trade paperback" },
	{ name: "hardcover", path: "hardcover" },
	{ name: "digest", path: "digest" },
	{ name: "graphic novel", path: "graphic novel" },
	{ name: "digital comic", path: "digital comic" },
	{ name: "infinite comic", path: "infinite comic" },
];
export { radioFormatOption };
