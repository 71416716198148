<template>
	<div v-for="(creator, idx) in comic.creators" :key="idx">
		<el-dialog
			v-model="dialogVisiblee"
			:fullscreen="true"
			@close="this.dialogVisiblee = false"
		>
			<h1 v-if="!loadingCreator" class="creatorName" style="color: white">
				Comics of {{ theCreator }}
			</h1>
		
			<Carousel
				v-if="!loadingCreator"
				@changeDialogValue="this.dialogVisiblee = false"
				:nameCreator="theCreator"
				:count="count"
				:total="total"
				:comicsByCreator="comicsByCreator"
				:loadingCreator="loadingCreator"
			/>
			<MainContent :creatorId="creator.id" @changeDialogValue="this.dialogVisiblee = false" />
		</el-dialog>

		<div class="review reviewFocus" v-if="comic.creators">
			<div
				class="review__content creator"
				@click="ComicSForCreator(creator.id, creator.name)"
			>
				<p class="review__name">
					{{ creator.role }} :
					<span class="review__title">
						{{ creator.name }}
					</span>
				</p>
			</div>
		</div>
	</div>
	<div class="review" v-if="comic.publishedDate">
		<div class="review__content">
			<p class="review__title">Publication date</p>
			<br />
			<p class="review__name">{{ comic.publishedDate }}</p>
		</div>
	</div>

	<div class="review" v-if="comic.language">
		<div class="review__content">
			<p class="review__title">Language</p>
			<br />
			<p class="review__name">{{ comic.language }}</p>
		</div>
	</div>
	<div class="review" v-if="comic.description">
		<div class="review__content">
			<p class="review__title" style="font-size: 15px">Description</p>
			<br />
			<p class="review__text">{{ comic.description }}</p>
		</div>
	</div>
</template>

<script>
	import Carousel from "@/components/Carousel";
	import { LoadData } from "@/Services/loadData";
	import moment from "moment";
	import Loading from "@/components/Loading.vue";
	import { Comic } from "@/types/Comic";
	import { createComic } from "@/Services/createComic";
	import MainContent from "./MainContent.vue";

	export default {
		name: "Review",
		components: { Carousel, Loading, MainContent },
		props: ["comic"],
		data() {
			return {
				loadingCreator: false,
				creatorObject: {},
				pic: "",
				comicsByCreator: [],
				dialogVisiblee: false,
				theCreator: "",
				count: 0,
				total: 0,
			};
		},
		setup() {},
		methods: {
			mountedPictureCreator() {
				for (let i = 0; i < this.comic.creators.length; i++) {
					LoadData.LoadCreator(
						this.comic.creators[i].resourceURI
					).then((response) => {
						const result = response.data.data.results;
						this.comic.creators[i].imgCoverCreator =
							result[0].thumbnail.path ==
							"http://i.annihil.us/u/prod/marvel/i/mg/b/40/image_not_available"
								? "https://images.assetsdelivery.com/compings_v2/luplupme/luplupme1606/luplupme160600094.jpg"
								: result[0].thumbnail.path +
								  "/landscape_incredible.jpg";

						this.comic.creators[i].id = result[0].id;
					});
				}
			},
			ComicSForCreator(id, theCreator) {
				this.comicsByCreator = [];
				this.theCreator = theCreator;
				this.loadingCreator = true;
				this.dialogVisiblee = true;
				let link = `https://gateway.marvel.com:443/v1/public/comics?creators=${id}`;

				LoadData.LoadCreator(link).then((response) => {
					this.count = response.data.data.count;
					this.total = response.data.data.total;
					const results = response.data.data.results;
					this.comicsByCreator = createComic.comicsArray(results);
					this.loadingCreator = false;
					// this.$store.commit("loadComicsObject", this.comics);
				});
			},
			format_date(value) {
				if (value) {
					return moment(value).format("DD MMM YYYY");
				}
			},
		},
		watch: {
			dialogVisiblee(value) {
				if (value == false) {
					this.comicsByCreator = [];
					this.theCreator = "";
				}
			},
		},
		mounted() {
			this.mountedPictureCreator();
		},
	};
</script>
<style scoped>
	@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");

	* {
		font-family: "Poppins", sans-serif;
		box-sizing: border-box;
	}
	.el-dialog__header {
		height: 2px !important;
	}
	.carousel-content {
		padding: 0px !important;
	}

	.review {
		padding: 16px 0px;
		text-align: left;
		display: flex;
		border-top: solid 1px #333;
	}
	.reviewFocus .review {
		border-top: none !important;
	}
	.creator {
		cursor: pointer;
	}

	.review__content {
		flex: 1;
	}

	.review__title {
		font-weight: bold;
		font-size: 13px;
		padding-left: 5x;
		padding-top: 10px;
	}

	.review__name {
		font-weight: 300;
		font-size: 14px;
		margin-top: -4px;
	}

	.review__text {
		font-size: 15px;
		font-weight: 300;
		text-align: justify;
	}

	.review__note {
		color: #ececec;
	}
	.review__digit {
		color: white;
		font-weight: bold;
		font-size: 32px;
	}
	.reviewFocus {
		text-align: center;
	}
	.reviewFocus:hover {
		display: block;
		text-align: center;
		background: rgba(60, 42, 39, 0.63);
		z-index: 9999;
		transition: transform 0.3s ease;
	}
</style>
