import { createStore, Store } from "vuex";
import { InjectionKey } from "vue";
import { Comic } from "../types/Comic";
import { LoadData } from "@/Services/loadData";
import moment from "moment";
import { createComic } from '../Services/createComic';


export interface State {
	comics: Comic[];
	comicsLastWeek: Comic[];
	currentComic: Comic;
	paginationPage: number;
	searchInput: string;
	activateDialogVisible: boolean;
	typeComic: string;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
	state: {
		comics: [],
		comicsLastWeek: [],
		currentComic: new Comic(),
		paginationPage: 0,
		searchInput: "",
		activateDialogVisible: false,
		typeComic: "",
	},
	getters: {
		getTypeComic(state) {
			return state.typeComic;
		},
		getActivateDialogVisible(state) {
			return state.activateDialogVisible;
		},
		getComics(state) {
			return state.comics, state.comicsLastWeek;
		},

		getCurrentComic(state) {
			return state.currentComic;
		},
	},
	mutations: {
		loadTypeComic(state, value: string) {
			state.typeComic = value;
		},
		loadActivateDialogVisible(state, value: boolean) {
			state.activateDialogVisible = value;
		},
		loadSearchInput(state, value: string) {
			state.searchInput = value;
		},

		loadComicsObject(state, value: Comic[]) {
			state.comics = value;
		},
		loadComicsLastWeek(state, value: Comic[]) {
			state.comicsLastWeek = value;
		},
		loadpaginationPage(state, value: number) {
			state.paginationPage = value;
		},
		setCurrentComic(state, comic) {
			state.currentComic = comic;
		},

		setCurrentComicVersionMutation(state, comicId) {
			state.currentComic = JSON.parse(JSON.stringify(state.currentComic));
			LoadData.LoadComic(comicId).then((response) => {
				let comicObjects = []
				const results = (response.data as any).data.results;
				// Add Object in Comics Table
				comicObjects = createComic.comicsArray(results)
					state.currentComic = comicObjects[0];
				
			});
		},
	},
	actions: {
		addTypeComic({ commit }, value: string) {
			commit("loadTypeComic", value);
		},
		addActivateDialogVisible({ commit }, value: boolean) {
			commit("loadActivateDialogVisible", value);
		},

		addSearchInput({ commit }, value: string) {
			commit("loadSearchInput", value);
		},
		addpaginationPage({ commit }, value: number) {
			commit("loadpaginationPage", value);
		},

		addComicsObject({ commit }, value) {
			commit("loadComicsObject", value);
		},
		addComicsLastWeek({ commit }, value) {
			commit("loadComicsLastWeek", value);
		},
		setCurrentComic({ commit, state }, comicId) {
			let comicFound = {};
			state.comics.forEach((comic) => {
				if (comicId == comic.id) {
					comicFound = comic;
				}
			});
			commit("setCurrentComic", comicFound);
		},
		setCurrentComicLastWeek({ commit, state }, comicId) {
			let comicFound = {};
			state.comicsLastWeek.forEach((comic) => {
				if (comicId == comic.id) {
					comicFound = comic;
				}
			});
			commit("setCurrentComicsLastWeek", comicFound);
		},
	},
});
