import { Options, Vue } from "vue-class-component";
import Search from "@/components/Search.vue";
import Carousel from "@/components/Carousel.vue";
import MainContent from "@/components/MainContent.vue";

@Options({
	components: { MainContent, Search, Carousel },
	data() {
		return { loadingC: true };
	},

	methods: {
		toggle_loading(value: boolean) {
			this.loadingC = value;
		},
	},

	computed: {
		searchInput() {
			return this.$store.state.searchInput;
		},
	},
	mounted() {
		this.$store.commit("loadSearchInput", "");
		this.$store.commit("loadTypeComic", "");
	},
})
export default class Home extends Vue {}
