<template>
	<div class="carousel-content">
		<h1 v-if="!this.loadingC" style="margin-top: 4rem">
			Comics added last week
		</h1>
	
		<carousel :settings="settings" :breakpoints="breakpoints">
			<slide v-for="(comicCar, ndx) in comicsLastWeek" :key="ndx">
				<router-link
					:to="{ name: 'comic', params: { id: comicCar.id } }"
					style="text-decoration: none !important"
				>
					<div class="comic" @click="pushComic(comicCar.id)">
						<div
							class="comic__image"
							:style="{
								backgroundImage:
									'url(\'' + comicCar.cover + '\')',
							}"
						></div>
						<h2 class="comic__title">
							{{ comicCar.titleC }}
							<br />
							added : {{ comicCar.publishedDate }}
						</h2>
					</div>
				</router-link>
			</slide>

			<template #addons>
				<navigation />
			</template>
		</carousel>
	</div>
</template>

<script>
	// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
	import "vue3-carousel/dist/carousel.css";
	import moment from "moment";
	import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

	import { LoadDataLastWeek } from "@/Services/loadData";
	import { createComic } from "@/Services/createComic";
	export default {
		name: "App",
		props: [
			"comicsByCreator",
			"nameCreator",
			"loadingCreator",
			"count",
			"total",
		],
		components: {
			Carousel,
			Slide,
			Pagination,
			Navigation,
		},
		data() {
			return {
				loadingC: true,
				comicsLastWeek: [],
				settings: {
					snapAlign: "center",
					itemsToScroll: 1,
					autoplay: 1000,
					transition: 3000,
					pauseAutoplayOnHover: "true",
					wrapAround: "true",
				},
				// breakpoints are mobile first
				// any settings not specified will fallback to the carousel settings
				breakpoints: {
					700: {
						itemsToShow: 3,
						snapAlign: "center",
					},
					// 1024 and up
					1024: {
						itemsToShow: 5,
						snapAlign: "start",
					},
				},
			};
		},
		methods: {
			callApiLastWeek() {
				this.comicsLastWeek = [];
				this.loadingC = true;

				LoadDataLastWeek.LoadDataComics().then((response) => {
					const results = response.data.data.results;
					this.loadingC = response.loadingC;
					this.$emit("toggle_loading" , this.loadingC)	
					this.comicsLastWeek = createComic.comicsArray(results);
					this.$store.commit(
						"loadComicsLastWeek",
						this.comicsLastWeek
					);
					
				});
			},
			format_date(value) {
				if (value) {
					return moment(value).format("DD MMM YYYY");
				}
			},
			pushComic(id) {
				if (this.comicsByCreator) {
					this.$store.commit("setCurrentComicVersionMutation", id);
					this.$emit("changeDialogValue");
				}
			},
		},
		created() {
		
			this.$emit("toggle_loading", this.loadingC);
			if (!this.comicsByCreator) {
				this.callApiLastWeek();
			} else {
				this.comicsLastWeek = this.comicsByCreator;
			}
		},
	};
</script>
<style scoped>
	h1 {
		font-family: "Rubik" !important;
		padding-bottom: 1rem;
		font-size: 2.5rem;
	}
	.comic {
		text-decoration: none;
		opacity: 0.85;
		transition: opacity 0.2s;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 14rem !important;
		height: 23rem !important;
		border-radius: 15px;
	}
	.comic__image {
		position: relative;
		max-width: 100%;
		background-size: cover;
		width: 14rem !important;
		height: 23rem !important;
		background-position: center center;
		border-radius: 8px;
	}
	.carousel__pagination {
		padding-top: 2rem;
	}

	.carousel {
		border-radius: 10px;
	}
	.carousel-content {
		justify-content: center;
		max-width: 100%;
		margin: 1em !important;
		border-radius: 10px;
	}
	/* .carousel__slide {
		width: 25%;
	} */

	.comic__title {
		font-size: 13px;
		font-weight: 300;
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: white;

		text-decoration: none !important;
	}
	.comic__title:hover {
		cursor: pointer;
		border-radius: 10px;
	}
	.comic__image:hover {
		cursor: pointer;
		border-radius: 10px;
	}
	.comic:hover {
		opacity: 2;
		border-radius: 10px;
	}
	@media (max-width: 900px) {
		.creatorName {
			font-size: 1em;
		}
		.carousel__pagination {
			display: none;
		}
		.comic__image {
			position: relative;
			max-width: 100%;
			background-size: cover;
			width: 16rem !important;
			height: 26rem !important;
			background-position: center center;
			border-radius: 8px;
		}
		.comic {
			text-decoration: none;
			opacity: 0.85;
			transition: opacity 0.2s;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 16rem !important;
			height: 26rem !important;
			border-radius: 15px;
		}
	}
	@media (min-width: 1740px) {
		.comic {
			text-decoration: none;
			opacity: 0.85;
			transition: opacity 0.2s;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 18rem !important;
			height: 28rem !important;
			border-radius: 15px;
		}
		.comic__image {
			position: relative;
			max-width: 100%;
			background-size: cover;
			width: 18rem !important;
			height: 28rem !important;
			background-position: center center;
			border-radius: 8px;
		}
		/* }
	@media (max-width: 1024px) {
		.comic {
			text-decoration: none;
			opacity: 0.85;
			transition: opacity 0.2s;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 9rem !important;
			height: 20rem !important;
			border-radius: 15px;
		}
		.comic__image {
			position: relative;
			max-width: 100%;
			background-size: cover;
			width: 9rem !important;
			height: 20rem !important;
			background-position: center center;
			border-radius: 8px;
		}*/
	}
</style>
<style>
	.carousel__slide > .carousel__item {
		transform: scale(1) !important;
		opacity: 0.5;
		transition: 0.5s;
	}
	.carousel__slide--visible > .carousel__item {
		opacity: 1;
		transform: rotateY(0) !important;
	}
	.carousel__slide--next > .carousel__item {
		transform: scale(0.9) translate(-10px) !important;
	}
	.carousel__slide--prev > .carousel__item {
		transform: scale(0.9) translate(10px) !important;
	}
	.carousel__slide--active > .carousel__item {
		transform: scale(1.1) !important;
	}
	.carousel__prev,
	.carousel__next,
	.carousel__pagination-button {
		background-color: #ef1616 !important;
		top: 40%;
	}
	.carousel__pagination-button--active {
		background-color: white !important;
	}
</style>
