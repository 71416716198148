import { Options, Vue } from "vue-class-component";
import { LoadData } from "@/Services/loadData";
import { Comic } from "../types/Comic";
import moment from "moment";

import Loading from "../components/Loading.vue";
import Carousel from "../components/Carousel.vue";
import { radioFormatOption } from "@/models/radioData";
import { colorChips } from "@/models/colorChips";
import { createComic } from "../Services/createComic";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
	components: { Loading, Carousel },
	props: ["creatorId"],
	data() {
		const loadingSearch: Boolean |any = false;
		const loadingPagination: Boolean |any = true;
		const loading: Boolean |any = true;
		const comics: any[] |Comic[]=[]
		return {
			radioFormatOption: radioFormatOption,
			colorChip: colorChips,
			dataHome: "",
			comic: new Comic(),
			comics,
			loadingPagination,
			loadingSearch,
			loading,
			error: false,
			resultsNumber: 0,
			paginationNum: 1,
			pagination: {
				total: 20,
				perPage: 8,
			},
		};
	},
	emits: ["changeDialogValue"],
	methods: {
		callApi() {
			this.comics = [];
			this.loading  = true ;
			if(this.creatorId){
				this.callApiForComicsCreator(this.creatorId)
			}else{
				this.callApiForComics()
			}
			
		},
		callApiForComics(){
			LoadData.LoadDataComics(this.axiosParams).then((response) => {
				this.resultsNumber = (response.data as any).data.total;
				this.loading = response.loadingG;
				this.loadingPagination = !response.loadingG;
				const results = (response.data as any).data.results;
				this.comics = createComic.comicsArray(results);
				this.$store.commit("loadComicsObject", this.comics);
				this.pagination.total = this.resultsNumber;
			});
		},
		callApiForComicsCreator(id: number) {
			let link = `https://gateway.marvel.com:443/v1/public/comics?creators=${id}`;

			LoadData.LoadCreator(link).then((response) => {
				this.resultsNumber = (response.data as any).data.total;
				this.loading = response.loadingC;
				this.loadingPagination = !response.loadingC;
				const results = (response.data as any).data.results;
				this.comics = createComic.comicsArray(results);
				this.$store.commit("loadComicsObject", this.comics);
				this.pagination.total = this.resultsNumber;
			});
		},

		setPage(val: number) {
			(this.paginationNum = val),
				this.$store.commit("loadpaginationPage", val);
		},
		format_date(value: string) {
			if (value) {
				return moment(String(value)).format("DD MMM YYYY");
			}
		},
		colorChipsFunction(value: string) {
			return this.colorChip.get(value);
		},
	},
	computed: {
		typeComic() {
			return this.$store.state.typeComic;
		},
		paginationPage() {
			return this.$store.state.paginationPage;
		},

		searchInput() {
			return this.$store.state.searchInput;
		},
		axiosParams() {
			const params = new URLSearchParams();
			if (!this.$store.state.paginationPage) {
				this.paginationNum = 1;
			} else {
				this.paginationNum = this.$store.state.paginationPage;
			}
			params.append("offset", `${(this.paginationNum - 1) * 20}`);

			if (this.searchInput && this.$route.name == "search") {
				this.setPage(1);
				params.append("titleStartsWith", `${this.searchInput}`);
			}

			if (this.typeComic) {
				this.$router.push({ path: "/search" });
				this.setPage(1);
				params.append("format", `${this.typeComic}`);
			}

			// params.append('parameter', `${value}`);
			return params;
		},
	},

	watch: {
		resultsNumber() {
			if (this.$route.name == "search") {
				// this.notify();
			}
		},
		typeComic() {
			this.callApi();
		},
		paginationPage() {
			this.callApi();
		},
		searchInput() {
			this.callApi();
		},
	},
	mounted() {
		if (!this.$store.state.paginationPage) {
			this.$store.commit("loadpaginationPage", 1);
		}

		this.callApi();
		if (!this.searchInput && !this.typeComic &&!this.creatorId) {
			this.$router.push("/");
		}
	},
})

